<header class="container" id="header">
  <nav>
    <!-- Existing header content -->
    <div class="main-logo">
      <a [routerLink]="['/content/home']">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="168.188"
          height="42.613"
          viewBox="122.861 102.401 168.188 42.613"
        >
          <g data-name="LOGO">
            <g data-name="Group 44">
              <path
                d="M230.99 141.895c-2.007-2.115-3.074-5.003-2.945-7.97-.116-3 .955-5.919 2.963-8.076 2-2.079 4.74-3.202 7.565-3.1 2.28-.058 4.488.826 6.142 2.456 1.627 1.574 2.524 3.8 2.463 6.109 0 1.194-.903 1.79-2.718 1.79h-12.29a8.889 8.889 0 0 0 2.248 6.03c1.41 1.615 3.425 2.511 5.52 2.458a7.818 7.818 0 0 0 4.27-1.347 4.122 4.122 0 0 1 1.418-.708c.262.025.509.137.704.32.242.18.387.469.393.778 0 .81-.5 1.557-1.498 2.242a10.762 10.762 0 0 1-3.31 1.523 12.03 12.03 0 0 1-3.162.491c-2.871.136-5.672-.945-7.763-2.996Zm12.29-11.107a6.748 6.748 0 0 0-1.274-4.133c-.89-1.09-2.223-1.678-3.595-1.587a5.693 5.693 0 0 0-4.14 1.765 5.954 5.954 0 0 0-1.891 4.045h4.972c2.21 0 4.185-.03 5.928-.09Z"
                fill="#1a406d"
                fill-rule="evenodd"
                data-name="Path 104815"
              />
              <path
                d="M256.042 144.298h-1.825c-1.858 0-3.001-.295-3.44-.885a1.555 1.555 0 0 1-.3-.983.808.808 0 0 1 .425-.786c.295-.15.617-.232.945-.24.341-.016.67-.136.946-.345.299-.246.458-.632.425-1.027v-10.857c0-1.312-.157-2.123-.473-2.433a2.73 2.73 0 0 0-.765-.595 9.682 9.682 0 0 0-.945-.329 7.512 7.512 0 0 1-1.074-.398.642.642 0 0 1-.415-.595c0-1.18 1.523-1.77 4.568-1.77 1.29 0 2.107.234 2.453.702.35.461.52 1.533.52 3.209a6.025 6.025 0 0 1 2.383-2.978 6.194 6.194 0 0 1 3.417-1.08 4.983 4.983 0 0 1 2.889.778 2.344 2.344 0 0 1 1.139 1.965 2.538 2.538 0 0 1-.749 1.917c-.494.48-1.15.737-1.825.717a2.945 2.945 0 0 1-1.857-.595 2.713 2.713 0 0 1-1.012-1.543 5.974 5.974 0 0 0-.912-.089c-2.207 0-3.31 2.642-3.31 7.927v6.094c0 .857.473 1.308 1.371 1.357.898.049 1.37.432 1.37 1.14.037.771-.531 1.429-1.272 1.474-.882.178-1.78.26-2.677.248Z"
                fill="#1a406d"
                fill-rule="evenodd"
                data-name="Path 104816"
              />
              <path
                d="M273.007 130.301a3.313 3.313 0 0 1-1.782-.653 1.948 1.948 0 0 1-.988-1.69 3.69 3.69 0 0 1 .576-1.966c.38-.615.89-1.133 1.49-1.514a9.073 9.073 0 0 1 2.121-.983 15.05 15.05 0 0 1 4.757-.733c2.859 0 4.937.655 6.235 1.966 1.298 1.31 1.946 3.484 1.943 6.522v6.536c0 1.576.19 2.56.572 2.95.29.19.61.324.946.397 1.446.492 2.172.836 2.172 1.081 0 1.547-1.523 2.32-4.57 2.32-1.148 0-1.894-.265-2.24-.796a5.797 5.797 0 0 1-.564-2.89 7.17 7.17 0 0 1-2.935 2.875 8.345 8.345 0 0 1-4.183 1.106 10.577 10.577 0 0 1-3.782-.596 5.47 5.47 0 0 1-2.514-1.932 4.845 4.845 0 0 1-.945-2.881 7.59 7.59 0 0 1 .335-2.457 5.568 5.568 0 0 1 .85-1.578c.4-.473.88-.863 1.419-1.15.529-.312 1.08-.581 1.648-.806a12.328 12.328 0 0 1 1.97-.54c1.192-.236 2.19-.4 2.997-.492.808-.098 1.224-.156 1.252-.167.029-.012.573-.069 1.457-.168a5.294 5.294 0 0 0 2.089-.585c0-2.797-.361-4.622-1.083-5.474-.638-.738-1.683-1.101-3.146-1.101a4.297 4.297 0 0 0-3.28 1.154c-.728.772-1.092 2.001-1.092 3.677v.491a13.79 13.79 0 0 1-1.725.077Zm10.266 3.136a13.482 13.482 0 0 1-3.256.761 19.56 19.56 0 0 0-2.86.492 7.094 7.094 0 0 0-1.74.708c-1.104.7-1.729 1.992-1.611 3.332 0 2.53 1.418 3.795 4.254 3.799 2.213.184 4.21-1.38 4.652-3.642.423-1.782.612-3.616.562-5.451Z"
                fill="#1a406d"
                fill-rule="evenodd"
                data-name="Path 104817"
              />
            </g>
            <g data-name="Group 43">
              <path
                d="M152.083 144.299h-2.247c-2.051 0-3.309-.32-3.781-.949a1.568 1.568 0 0 1-.322-.983.786.786 0 0 1 .393-.761c.329-.162.682-.263 1.045-.3.31-.028.613-.102.902-.221.539-.297.85-.903.79-1.534v-9.87h-18.188v9.87a1.683 1.683 0 0 0 .501 1.381c.352.284.776.454 1.22.492a5.7 5.7 0 0 1 .912.142.75.75 0 0 1 .577.84c.02.4-.093.794-.322 1.116a1.86 1.86 0 0 1-1.167.56c-1.344.188-2.7.262-4.056.221a23.75 23.75 0 0 1-3.782-.177c-1.087-.295-1.654-.894-1.696-1.808 0-.519.482-.846 1.447-.983.312-.036.62-.108.916-.216.577-.278.911-.914.824-1.568v-21.526a1.656 1.656 0 0 0-.392-1.29 1.524 1.524 0 0 0-1.198-.526c-.899-.049-1.384-.211-1.47-.417a2.169 2.169 0 0 1-.127-.814 1.46 1.46 0 0 1 .317-.92 1.86 1.86 0 0 1 1.15-.559 26.165 26.165 0 0 1 4.022-.202c1.263-.04 2.527.019 3.782.177 1.134.282 1.716.886 1.744 1.814 0 .525-.496.85-1.489.983a3.097 3.097 0 0 0-.888.176 1.54 1.54 0 0 0-.832 1.593v9.102h18.192v-9.117a1.658 1.658 0 0 0-.405-1.273 1.527 1.527 0 0 0-1.188-.518c-.469.01-.93-.122-1.328-.379a1.007 1.007 0 0 1-.246-.717 1.52 1.52 0 0 1 .194-.778c.146-.238.361-.42.614-.521a9.67 9.67 0 0 1 .818-.312c.372-.096.752-.15 1.135-.162a30.893 30.893 0 0 1 1.829-.069h.808c1.318-.074 2.64.038 3.928.335 1.115.412 1.678.982 1.678 1.656a.68.68 0 0 1-.407.674c-.34.14-.696.23-1.06.265a3.23 3.23 0 0 0-.893.187 1.608 1.608 0 0 0-.824 1.612v21.526a1.693 1.693 0 0 0 .497 1.381c.35.281.77.452 1.21.492.318.024.634.072.946.142a.663.663 0 0 1 .552.717c.01.312-.045.622-.16.91a1.207 1.207 0 0 1-.563.55 5.188 5.188 0 0 1-.724.32 4.186 4.186 0 0 1-1.02.156c-.707.06-1.428.08-2.168.08Z"
                fill="#1a406d"
                fill-rule="evenodd"
                data-name="Path 104812"
              />
              <path
                d="M164.96 144.299h-1.825c-1.858 0-3.001-.295-3.441-.885a1.555 1.555 0 0 1-.3-.983.808.808 0 0 1 .426-.787c.295-.15.617-.232.945-.24a1.66 1.66 0 0 0 .946-.344c.3-.248.459-.635.424-1.031v-23.262c0-1.343-.509-2.066-1.526-2.168l-.407-.044-.34-.03-.264-.044a.92.92 0 0 1-.237-.078.732.732 0 0 1-.137-.113c-.1-.083-.15-.3-.15-.638a1.48 1.48 0 0 1 .653-1.057 4.768 4.768 0 0 1 3.204-1.017 6.274 6.274 0 0 1 2.506.265c.452.298.727.815.728 1.372v26.867c0 .856.472 1.307 1.37 1.356.898.05 1.371.433 1.371 1.14.036.772-.532 1.43-1.273 1.475-.881.176-1.777.258-2.673.246Z"
                fill="#1a406d"
                fill-rule="evenodd"
                data-name="Path 104813"
              />
              <path
                d="m174.078 126.016-1.295.025a3.113 3.113 0 0 1-1.15-.156.627.627 0 0 1-.359-.649c.008-.35.157-.68.412-.91a3.01 3.01 0 0 1 .945-.629 4.36 4.36 0 0 0 1.753-1.046 12.32 12.32 0 0 0 .861-1.887c.945-2.428 1.733-3.642 2.363-3.642.326 0 .515.364.563 1.09.047.727.08 1.283.085 1.666.005.384 0 .954 0 1.714v1.582h5.058c.447-.015.887.121 1.252.39.327.23.52.616.511 1.027.008.396-.181.768-.501.983a2.09 2.09 0 0 1-1.243.353h-5.094v11.299c0 3.194.772 4.792 2.316 4.792a6.712 6.712 0 0 0 1.89-.413 7.15 7.15 0 0 1 1.637-.408c.576 0 .87.349.87 1.037 0 .547-.685 1.093-2.052 1.636-1.34.54-2.765.816-4.202.816-1.339.054-2.612-.603-3.38-1.745a7.852 7.852 0 0 1-1.22-4.59l-.02-12.335Z"
                fill="#1a406d"
                fill-rule="evenodd"
                data-name="Path 104814"
              />
            </g>
            <g data-name="hlthera">
              <path
                d="M214.962 121.592v20.59h5.524v-22.003c-2.141.782-4.55 1.244-5.524 1.413Z"
                fill="#1a406d"
                fill-rule="evenodd"
                data-name="Path 111"
              />
              <path
                d="M2747.059 342.31h.003l.03.002-.033-.001Z"
                fill-rule="evenodd"
                fill='url("#a")'
                transform="translate(-2534.173 -224.372)"
                data-name="Path 96"
              />
              <path
                d="m2749.36 339.05-.004-.001h.004Z"
                fill-rule="evenodd"
                fill='url("#b")'
                transform="translate(-2536.136 -221.604)"
                data-name="Path 97"
              />
              <g data-name="Group 45">
                <path
                  d="M.466 22.375c-.463-1.486-.555-3.097-.39-4.571.02-.196.046-.388.077-.575.229-1.429.695-2.685 1.286-3.51.274-.383.572-.745.893-1.086 1.622-1.808 3.706-2.703 4.913-3.099 2.89-1.035 5.666-1.1 6.6-1.284 4.574-.896 13.539-.613 19.108-2.074.555-.146 2.31-.801 3.034-1.367.969-.757 1.409-1.555 1.305-2.241-.229-1.011-1.534-1.407-1.534-1.407S34.522.74 32.895.774C31.27.809 27.407.58 27.407.58h-1.813l.187-.429h1.739l5.375-.105.013-.001V.002l.181-.001c4.608-.038 6.003 2.39 4.43 4.573-.27.397-.662.823-1.224 1.25-2.123 1.608-7.014 2.348-7.014 2.348l-1.874.293-10.435 1.31s-3.066.405-3.286.493c-.22.088-5.455 1.444-5.455 1.444s-5.67 1.596-5.432 7.31c-.017 0-.02 3.514 2.567 4.726 1.382.53 2.82.294 2.82.294l.414-.005.072-.013a.07.07 0 0 0-.002.012l3.183-.04v3.676H8.672v.016s-.24.027-.64.027c-1.666 0-6.121-.47-7.566-5.34Z"
                  fill-rule="evenodd"
                  fill='url("#c")'
                  transform="translate(187.579 117.299)"
                  data-name="Union 1"
                />
              </g>
              <g data-name="Group 42">
                <path
                  d="M202.983 118.312a.192.192 0 0 0-.178.097.208.208 0 0 0 0 .209.192.192 0 0 0 .178.097h.766c-.09-.133-.17-.27-.25-.403h-.516Z"
                  fill="#2e7ac5"
                  fill-rule="evenodd"
                  data-name="Path 104818"
                />
                <path
                  d="M207.956 115.863a.194.194 0 0 1 .198-.138.191.191 0 0 1 .175.156l.884 3.932.861-2.09.557-1.35a.184.184 0 0 1 .18-.123c.078-.001.149.048.18.123l.543 1.41.501-1.23a.195.195 0 0 1 .161-.122.178.178 0 0 1 .18.089l.586.928h.393c.065-.16.12-.323.165-.49.714-2.87-2.5-5.347-5.289-2.457-2.788-2.9-5.998-.423-5.284 2.456.128.474.324.924.58 1.337h3.654l.775-2.43Z"
                  fill="#2e7ac5"
                  fill-rule="evenodd"
                  data-name="Path 104819"
                />
                <path
                  d="m212.682 117.787-.472-.701-.549 1.323a.194.194 0 0 1-.18.126.194.194 0 0 1-.18-.126l-.51-1.401-1.465 3.577a.194.194 0 0 1-.18.123.2.2 0 0 1-.174-.156l-.875-3.893-.614 1.927a.2.2 0 0 1-.184.137h-3.545a20.325 20.325 0 0 0 4.452 4.3s3.664-2.545 4.94-5.14h-.317a.202.202 0 0 1-.147-.096Z"
                  fill="#2e7ac5"
                  fill-rule="evenodd"
                  data-name="Path 104820"
                />
                <path
                  d="M213.346 117.441h1.832v.458l-2.03-.018.198-.44Z"
                  fill="#2e7ac5"
                  fill-rule="evenodd"
                  data-name="Path 150"
                />
              </g>
              <path
                d="M2749.39 339.056Z"
                fill-rule="evenodd"
                fill='url("#d")'
                transform="translate(-2536.166 -221.61)"
                data-name="Path 101"
              />
              <path
                d="m2747.348 342.321.022.001.038.002c-.023 0-.043-.002-.06-.003Z"
                fill-rule="evenodd"
                fill='url("#e")'
                transform="translate(-2534.42 -224.381)"
                data-name="Path 102"
              />
              <path
                d="M2749.481 339.081Z"
                fill-rule="evenodd"
                fill='url("#f")'
                transform="translate(-2536.242 -221.632)"
                data-name="Path 103"
              />
              <g data-name="Path 104">
                <path
                  d="M2766.853 338.27v.741a15.47 15.47 0 0 0-.453.01c-1.66.064-3.616.013-5.071-.045v-.616l5.524-.09Z"
                  fill-rule="evenodd"
                  fill='url("#g")'
                  transform="translate(-2546.367 -220.943)"
                />
                <path
                  d="M220.486 117.327v.741a15.47 15.47 0 0 0-.453.01c-1.66.063-3.616.013-5.071-.045v-.617l5.524-.09Z"
                  stroke-linejoin="round"
                  stroke-linecap="round"
                  stroke="rgba(0,0,0,0 )"
                  fill="transparent"
                />
              </g>
              <path
                d="m213.072 117.947-.075-.004.075.004Z"
                fill-rule="evenodd"
                data-name="Path 108"
              />
              <path
                d="M212.883 117.938h-.026.026Z"
                fill-rule="evenodd"
                data-name="Path 109"
              />
              <g data-name="Group 41">
                <g data-name="Group 39">
                  <g data-name="Group 38">
                    <g data-name="Group 36">
                      <path
                        d="M195.765 102.401h24.721v3.013h-24.72v-3.013z"
                        fill="#1a406d"
                        fill-rule="evenodd"
                        data-name="Rectangle 10"
                      />
                    </g>
                    <g data-name="Group 37">
                      <path
                        d="M224.29 108.239h-32.238v.942h3.713v2.071h24.721v-2.071h3.804v-.942Z"
                        fill="#1a406d"
                        fill-rule="evenodd"
                        data-name="Path 105"
                      />
                    </g>
                  </g>
                </g>
                <path
                  d="M220.486 144.065v-2.072h-6.882v-12.9h-10.866v12.9h-6.973v3.013h9.87v-12.9h5.072v12.9h13.583v-.941h-3.804Z"
                  fill="#1a406d"
                  fill-rule="evenodd"
                  data-name="Path 107"
                />
                <g data-name="Group 40">
                  <path
                    d="M220.487 111.205h-4.29c-.873 0-1.235 1.263-1.235 1.906v9.156c1.999-.168 3.904-.398 5.525-.75v-10.312Z"
                    fill="#1a406d"
                    fill-rule="evenodd"
                    data-name="Path 110"
                  />
                </g>
                <path
                  d="M201.29 111.203h-5.525v30.979h4.29c.872 0 1.235-1.507 1.235-2.274v-28.705Z"
                  fill="#1a406d"
                  fill-rule="evenodd"
                  data-name="Path 106"
                />
              </g>
            </g>
          </g>
          <defs>
            <linearGradient
              x1="-457.232"
              y1="21645.5"
              x2="-2.191"
              y2="127.857"
              id="a"
            >
              <stop stop-color="#1b406d" offset="0" />
              <stop stop-color="#268ddd" offset="1" />
            </linearGradient>
            <linearGradient
              x1="-688.161"
              y1="5593.03"
              x2="673.277"
              y2="-5463.3"
              id="b"
            >
              <stop stop-color="#1b406d" offset="0" />
              <stop stop-color="#268ddd" offset="1" />
            </linearGradient>
            <linearGradient x1=".179" y1="1.018" x2=".675" y2="-.006" id="c">
              <stop stop-color="#1a406d" offset="0" />
              <stop stop-color="#1a406d" offset=".15" />
              <stop stop-color="#2e7ac5" offset=".83" />
              <stop stop-color="#2e7ac5" offset="1" />
            </linearGradient>
            <linearGradient
              x1="-653.664"
              y1="5833.38"
              x2="654.068"
              y2="-5830.99"
              id="d"
            >
              <stop stop-color="#1b406d" offset="0" />
              <stop stop-color="#268ddd" offset="1" />
            </linearGradient>
            <linearGradient
              x1="-249.054"
              y1="9637.25"
              x2="-1.19"
              y2="65.742"
              id="e"
            >
              <stop stop-color="#1b406d" offset="0" />
              <stop stop-color="#268ddd" offset="1" />
            </linearGradient>
            <linearGradient
              x1="-688.518"
              y1="5487.86"
              x2="689.374"
              y2="-5485.5"
              id="f"
            >
              <stop stop-color="#1b406d" offset="0" />
              <stop stop-color="#268ddd" offset="1" />
            </linearGradient>
            <linearGradient x1="-2.472" y1="37.105" x2=".239" y2="3.744" id="g">
              <stop stop-color="#1b406d" offset="0" />
              <stop stop-color="#268ddd" offset="1" />
            </linearGradient>
          </defs>
        </svg>
      </a>
    </div>
    <ul class="nav-bar">
      <li>
        <h4 class="mx-4 text-blue">{{ "welcome" | translate }}</h4>
      </li>
      <!-- <li>
        <div id="toggel-btn" class="togel-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </li> -->
      <!-- <li>
        <div id="language-switcher" class="lang-icon flex-center-all">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33.055"
            height="32.483"
            viewBox="0 0 33.055 32.483"
          >
            <path
              id="Path_319210"
              data-name="Path 319210"
              d="M3.918,20.941A16.432,16.432,0,0,0,6.6,29.956a18.426,18.426,0,0,1,5.652-4.429q.508-.257,1.026-.477c-.122-1.331-.183-2.707-.183-4.108,0-1.5.073-2.979.213-4.4a18.01,18.01,0,0,1-6.6-4.776,16.434,16.434,0,0,0-2.791,9.174Zm13.262,16.2a16.539,16.539,0,0,1-9.541-5.769,16.491,16.491,0,0,1,5.84-4.553,29.952,29.952,0,0,0,1.573,6.511,12.833,12.833,0,0,0,2.127,3.811Zm3.265.038c2.149,0,4.5-4.329,5.328-11.014a16.7,16.7,0,0,0-10.656,0C15.949,32.855,18.3,37.183,20.445,37.183Zm12.806-5.807a16.538,16.538,0,0,1-9.541,5.769,12.825,12.825,0,0,0,2.129-3.809,29.93,29.93,0,0,0,1.572-6.512A16.487,16.487,0,0,1,33.251,31.376Zm.933-19.612a18.014,18.014,0,0,1-6.6,4.777c.14,1.421.213,2.9.213,4.4,0,1.4-.063,2.777-.183,4.108q.518.223,1.027.477a18.412,18.412,0,0,1,5.65,4.43,16.494,16.494,0,0,0-.106-18.191ZM25.839,8.549A12.823,12.823,0,0,0,23.71,4.737a16.543,16.543,0,0,1,9.422,5.624,16.332,16.332,0,0,1-5.762,4.405,29.579,29.579,0,0,0-1.532-6.216ZM13.52,14.766a16.34,16.34,0,0,1-5.762-4.4A16.541,16.541,0,0,1,17.18,4.737a12.823,12.823,0,0,0-2.129,3.813,29.622,29.622,0,0,0-1.532,6.217Zm1.636.642a16.757,16.757,0,0,0,5.277.849h.012a16.762,16.762,0,0,0,5.289-.849C24.87,8.9,22.562,4.7,20.445,4.7S16.021,8.9,15.156,15.407ZM14.8,20.941c0,1.213.048,2.379.137,3.491a18.348,18.348,0,0,1,11.011,0c.089-1.112.137-2.278.137-3.491,0-1.325-.057-2.594-.162-3.8a18.5,18.5,0,0,1-5.48.824h-.013a18.523,18.523,0,0,1-5.471-.825C14.86,18.347,14.8,19.615,14.8,20.941Z"
              transform="translate(-3.918 -4.7)"
              fill="#8794aa"
              fill-rule="evenodd"
            />
          </svg>
        </div>
      </li> -->
    </ul>
  </nav>
  <div id="black-overlay" class="overlay"></div>
</header>
