const facbook = `<svg xmlns="http://www.w3.org/2000/svg" width="9.52" height="19.906" viewBox="0 0 9.52 19.906">
  <path id="Path_7" data-name="Path 7" d="M22.6,33.361H18.382V23.406H16.273v-3.43h2.109v-2.06c0-2.8,1.19-4.462,4.575-4.462h2.817v3.431h-1.76c-1.318,0-1.405.479-1.405,1.373l0,1.717h3.19l-.373,3.43H22.6V33.36Z" transform="translate(-16.273 -13.455)" fill="#8794aa" fill-rule="evenodd"/>
</svg>
`;
const instigram = `<svg xmlns="http://www.w3.org/2000/svg" width="18.143" height="18.143" viewBox="0 0 18.143 18.143">
  <g id="Group_116441" data-name="Group 116441" transform="translate(0 0)">
    <path id="Path_312524" data-name="Path 312524" d="M265.072,256c-2.464,0-2.773.01-3.74.055a6.659,6.659,0,0,0-2.2.422,4.639,4.639,0,0,0-2.653,2.653,6.658,6.658,0,0,0-.422,2.2c-.044.968-.055,1.276-.055,3.74s.01,2.773.055,3.74a6.658,6.658,0,0,0,.422,2.2,4.639,4.639,0,0,0,2.653,2.653,6.659,6.659,0,0,0,2.2.422c.968.044,1.276.055,3.74.055s2.773-.01,3.74-.055a6.658,6.658,0,0,0,2.2-.422,4.638,4.638,0,0,0,2.653-2.653,6.658,6.658,0,0,0,.422-2.2c.044-.968.055-1.277.055-3.74s-.01-2.773-.055-3.74a6.658,6.658,0,0,0-.422-2.2,4.639,4.639,0,0,0-2.653-2.653,6.658,6.658,0,0,0-2.2-.422C267.844,256.01,267.535,256,265.072,256Zm0,1.635c2.422,0,2.709.009,3.666.053a5.021,5.021,0,0,1,1.684.312,3,3,0,0,1,1.722,1.722,5.018,5.018,0,0,1,.312,1.684c.044.957.053,1.244.053,3.666s-.009,2.709-.053,3.666a5.018,5.018,0,0,1-.312,1.684,3,3,0,0,1-1.722,1.722,5.017,5.017,0,0,1-1.684.312c-.956.044-1.243.053-3.666.053s-2.709-.009-3.666-.053a5.018,5.018,0,0,1-1.684-.312A3,3,0,0,1,258,270.422a5.019,5.019,0,0,1-.312-1.684c-.044-.957-.053-1.243-.053-3.666s.009-2.709.053-3.666a5.019,5.019,0,0,1,.312-1.684A3,3,0,0,1,259.721,258a5.021,5.021,0,0,1,1.684-.312C262.362,257.644,262.649,257.635,265.072,257.635Z" transform="translate(-256 -256)" fill="#8794aa" fill-rule="evenodd"/>
    <path id="Path_312525" data-name="Path 312525" d="M385.2,388.222a3.024,3.024,0,1,1,3.024-3.024A3.024,3.024,0,0,1,385.2,388.222Zm0-7.682a4.658,4.658,0,1,0,4.658,4.658A4.658,4.658,0,0,0,385.2,380.54Z" transform="translate(-376.127 -376.127)" fill="#8794aa" fill-rule="evenodd"/>
    <path id="Path_312526" data-name="Path 312526" d="M620.109,345.716a1.089,1.089,0,1,1-1.089-1.089A1.089,1.089,0,0,1,620.109,345.716Z" transform="translate(-605.107 -341.486)" fill="#8794aa" fill-rule="evenodd"/>
  </g>
</svg>
`;
const twitter = `<svg xmlns="http://www.w3.org/2000/svg" width="14.739" height="15.063" viewBox="0 0 14.739 15.063">
  <path id="logo" d="M8.772,6.378,14.259,0h-1.3L8.194,5.538,4.389,0H0L5.754,8.374,0,15.063H1.3L6.331,9.215l4.019,5.848h4.389L8.771,6.378ZM6.991,8.448l-.583-.834L1.769.979h2L7.51,6.334l.583.834,4.866,6.961h-2L6.991,8.449Z" fill="#8794aa"/>
</svg>
`;

const linkedIn = `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 14.108 15.102">
  <g id="Layer_x0020_1" transform="translate(0 0)">
    <path id="Path_4" data-name="Path 4" d="M818.607,721.069h-3.12V714.5a1.391,1.391,0,0,0-.191-.826.908.908,0,0,0-.785-.293,1.625,1.625,0,0,0-1.672,1.138v6.554h-3.28v-10.7h3.28v.847a5.272,5.272,0,0,1,.867-.454,5.1,5.1,0,0,1,1.941-.386,2.867,2.867,0,0,1,2.322,1.184,5,5,0,0,1,.8,3.023v6.486h-.161Z" transform="translate(-804.659 -705.967)" fill="#8794aa"/>
    <path id="Path_5" data-name="Path 5" d="M332.474,275.107a1.554,1.554,0,0,1,0-3.108h0a1.566,1.566,0,0,1,1.095.459,1.539,1.539,0,0,1,.454,1.091,1.554,1.554,0,0,1-1.55,1.558Z" transform="translate(-330.829 -271.998)" fill="#8794aa"/>
    <path id="Path_6" data-name="Path 6" d="M324.638,721.071h-3.129v-10.7h3.29v10.7Z" transform="translate(-321.509 -705.969)" fill="#8794aa"/>
  </g>
</svg>
`;

export const SochialIcons = {
  facbook,
  instigram,
  twitter,
  linkedIn,
};
