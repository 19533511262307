<footer>
  <div class="footer-content">
    <div class="footer-logo">
      <a href="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="239.304"
          height="58.315"
          viewBox="253.347 1037.085 239.304 58.315"
        >
          <g data-name="Group 116899">
            <g data-name="Group 44">
              <path
                d="M407.197 1091.132a14.6 14.6 0 0 1-4.19-10.908 15.027 15.027 0 0 1 4.217-11.05 14.514 14.514 0 0 1 10.763-4.244 12.274 12.274 0 0 1 8.74 3.362 11.01 11.01 0 0 1 3.503 8.36c0 1.634-1.284 2.45-3.867 2.45h-17.485a11.898 11.898 0 0 0 3.197 8.252 10.27 10.27 0 0 0 7.855 3.363 11.434 11.434 0 0 0 6.075-1.843 5.958 5.958 0 0 1 2.017-.969c.373.034.724.187 1.002.437.344.248.55.642.559 1.066 0 1.107-.71 2.13-2.132 3.067a15.603 15.603 0 0 1-4.708 2.084 17.743 17.743 0 0 1-4.5.673 15.099 15.099 0 0 1-11.046-4.1Zm17.486-15.2a8.991 8.991 0 0 0-1.811-5.656 6.195 6.195 0 0 0-5.116-2.172 8.26 8.26 0 0 0-5.891 2.415 8.024 8.024 0 0 0-2.69 5.535h7.075c3.143 0 5.955-.04 8.434-.121Z"
                fill="#1a406d"
                fill-rule="evenodd"
                data-name="Path 104815"
              />
              <path
                d="M442.842 1094.42h-2.596c-2.643 0-4.27-.404-4.896-1.21a2.072 2.072 0 0 1-.426-1.346c-.039-.449.201-.876.605-1.076.42-.205.878-.318 1.345-.33.485-.02.953-.184 1.345-.47.425-.338.653-.866.606-1.406v-14.857c0-1.796-.225-2.906-.673-3.33a3.868 3.868 0 0 0-1.09-.814c-.44-.172-.889-.323-1.344-.45a10.964 10.964 0 0 1-1.527-.545.881.881 0 0 1-.592-.814c0-1.615 2.167-2.422 6.501-2.422 1.834 0 2.997.32 3.49.96.498.631.74 2.098.74 4.391a8.312 8.312 0 0 1 3.39-4.075 9.058 9.058 0 0 1 4.863-1.48 7.305 7.305 0 0 1 4.109 1.066c.975.55 1.59 1.571 1.62 2.69a3.404 3.404 0 0 1-1.065 2.623 3.652 3.652 0 0 1-2.596.982 4.304 4.304 0 0 1-2.643-.814 3.705 3.705 0 0 1-1.44-2.113 8.828 8.828 0 0 0-1.298-.121c-3.139 0-4.708 3.616-4.708 10.848v8.34c0 1.172.672 1.79 1.95 1.856 1.278.068 1.95.592 1.95 1.56a1.927 1.927 0 0 1-1.811 2.018 18.702 18.702 0 0 1-3.81.339Z"
                fill="#1a406d"
                fill-rule="evenodd"
                data-name="Path 104816"
              />
              <path
                d="M466.98 1075.266a4.83 4.83 0 0 1-2.535-.894 2.65 2.65 0 0 1-1.406-2.314 4.91 4.91 0 0 1 .82-2.69 6.63 6.63 0 0 1 2.12-2.072 13.154 13.154 0 0 1 3.019-1.345 22.196 22.196 0 0 1 6.768-1.003c4.067 0 7.024.897 8.871 2.69 1.847 1.794 2.769 4.769 2.765 8.926v8.944c0 2.157.27 3.503.814 4.036.412.26.868.445 1.345.545 2.058.673 3.09 1.144 3.09 1.48 0 2.116-2.167 3.174-6.5 3.174-1.635 0-2.697-.363-3.188-1.09a7.675 7.675 0 0 1-.803-3.954 10.028 10.028 0 0 1-4.176 3.935 12.24 12.24 0 0 1-5.952 1.513 15.595 15.595 0 0 1-5.38-.816 7.754 7.754 0 0 1-3.578-2.643 6.463 6.463 0 0 1-1.345-3.944c-.034-1.14.128-2.276.477-3.362a7.533 7.533 0 0 1 1.21-2.159 6.793 6.793 0 0 1 2.018-1.574 17.067 17.067 0 0 1 2.345-1.103 18.093 18.093 0 0 1 2.804-.74c1.695-.322 3.116-.547 4.264-.672 1.15-.135 1.74-.213 1.781-.23.04-.015.815-.093 2.072-.229a7.762 7.762 0 0 0 2.972-.8c0-3.829-.513-6.326-1.54-7.492-.907-1.009-2.394-1.507-4.475-1.507a6.262 6.262 0 0 0-4.668 1.58c-1.036 1.057-1.554 2.738-1.554 5.032v.672c-.816.085-1.635.12-2.455.106Zm14.608 4.29a19.822 19.822 0 0 1-4.634 1.043 28.84 28.84 0 0 0-4.069.672c-.863.215-1.694.54-2.474.969a4.829 4.829 0 0 0-2.293 4.56c0 3.462 2.017 5.194 6.053 5.199a6.244 6.244 0 0 0 6.618-4.984c.602-2.439.871-4.948.8-7.46Z"
                fill="#1a406d"
                fill-rule="evenodd"
                data-name="Path 104817"
              />
            </g>
            <g data-name="Group 43">
              <path
                d="M294.926 1094.421h-3.197c-2.919 0-4.708-.437-5.38-1.298a2.092 2.092 0 0 1-.458-1.345c-.041-.428.179-.84.558-1.042.469-.221.972-.36 1.487-.41a4.52 4.52 0 0 0 1.284-.303 2.131 2.131 0 0 0 1.123-2.098v-13.507h-25.877v13.507c-.074.707.19 1.407.713 1.889.5.388 1.103.621 1.735.672.437.031.87.097 1.298.195.535.106.895.61.82 1.15a2.36 2.36 0 0 1-.457 1.528 2.69 2.69 0 0 1-1.661.766 35.702 35.702 0 0 1-5.771.302 35.122 35.122 0 0 1-5.381-.242c-1.546-.403-2.353-1.224-2.413-2.474 0-.71.686-1.159 2.058-1.345.445-.05.882-.15 1.305-.297a2.055 2.055 0 0 0 1.172-2.144v-29.46a2.208 2.208 0 0 0-2.263-2.484c-1.278-.067-1.97-.29-2.092-.572a2.865 2.865 0 0 1-.18-1.113 1.95 1.95 0 0 1 .451-1.258 2.69 2.69 0 0 1 1.635-.766 38.692 38.692 0 0 1 5.724-.276 35.531 35.531 0 0 1 5.38.242c1.615.386 2.442 1.213 2.483 2.482 0 .72-.707 1.164-2.119 1.345a4.56 4.56 0 0 0-1.264.242 2.09 2.09 0 0 0-1.184 2.18v12.455h25.884v-12.476a2.212 2.212 0 0 0-2.267-2.451 3.464 3.464 0 0 1-1.889-.518 1.345 1.345 0 0 1-.35-.982 2.017 2.017 0 0 1 .276-1.065c.208-.325.515-.575.874-.713.404-.169.794-.31 1.164-.427a7.714 7.714 0 0 1 1.615-.221c1.03-.061 1.897-.094 2.602-.094h1.15a20.645 20.645 0 0 1 5.589.457c1.587.565 2.387 1.345 2.387 2.267a.928.928 0 0 1-.579.922c-.482.192-.99.315-1.507.363a4.755 4.755 0 0 0-1.271.256 2.18 2.18 0 0 0-1.172 2.206v29.459a2.267 2.267 0 0 0 .706 1.889 3.283 3.283 0 0 0 1.722.673c.453.033.902.098 1.346.195a.914.914 0 0 1 .786.981c.013.427-.065.85-.23 1.245a1.688 1.688 0 0 1-.8.753 7.533 7.533 0 0 1-1.029.437c-.474.129-.961.2-1.452.213-1.006.083-2.032.11-3.084.11Z"
                fill="#1a406d"
                fill-rule="evenodd"
                data-name="Path 104812"
              />
              <path
                d="M313.247 1094.421h-2.596c-2.643 0-4.27-.404-4.896-1.21a2.072 2.072 0 0 1-.426-1.346c-.04-.449.201-.876.605-1.076.42-.206.878-.318 1.345-.33a2.421 2.421 0 0 0 1.345-.47c.426-.339.654-.87.604-1.411v-31.834c0-1.838-.724-2.827-2.172-2.966l-.578-.06-.484-.041-.376-.061a1.345 1.345 0 0 1-.337-.107 1.035 1.035 0 0 1-.195-.154c-.142-.114-.213-.41-.213-.874a2.018 2.018 0 0 1 .928-1.447 6.968 6.968 0 0 1 4.56-1.391 9.263 9.263 0 0 1 3.565.363 2.226 2.226 0 0 1 1.036 1.877v36.767c0 1.173.672 1.79 1.95 1.857 1.278.067 1.95.591 1.95 1.56a1.927 1.927 0 0 1-1.812 2.017 18.745 18.745 0 0 1-3.803.337Z"
                fill="#1a406d"
                fill-rule="evenodd"
                data-name="Path 104813"
              />
              <path
                d="m326.22 1069.401-1.842.034a4.593 4.593 0 0 1-1.635-.213.853.853 0 0 1-.511-.888c.01-.479.223-.93.585-1.244.39-.369.847-.661 1.345-.861a6.257 6.257 0 0 0 2.495-1.433c.482-.824.891-1.688 1.224-2.582 1.345-3.322 2.466-4.983 3.363-4.983.463 0 .733.497.8 1.492.067.994.114 1.755.122 2.28.007.524 0 1.304 0 2.344v2.165h7.196a2.919 2.919 0 0 1 1.782.533c.465.315.739.844.727 1.406a1.56 1.56 0 0 1-.713 1.345 3.06 3.06 0 0 1-1.77.484h-7.246v15.462c0 4.372 1.098 6.558 3.295 6.558a9.875 9.875 0 0 0 2.69-.565 10.506 10.506 0 0 1 2.327-.559c.821 0 1.238.478 1.238 1.42 0 .749-.973 1.495-2.92 2.238a16.533 16.533 0 0 1-5.978 1.117 5.602 5.602 0 0 1-4.808-2.388 10.435 10.435 0 0 1-1.735-6.28l-.03-16.882Z"
                fill="#1a406d"
                fill-rule="evenodd"
                data-name="Path 104814"
              />
            </g>
            <g data-name="hlthera">
              <path
                d="M384.392 1063.348v28.176h7.86v-30.11c-3.047 1.07-6.475 1.702-7.86 1.934Z"
                fill="#1a406d"
                fill-rule="evenodd"
                data-name="Path 111"
              />
              <path
                d="M2747.06 342.31h.003l.043.002-.047-.001Z"
                fill-rule="evenodd"
                fill='url("#a")'
                transform="translate(-2365.62 716.037)"
                data-name="Path 96"
              />
              <path
                d="m2749.362 339.05-.006-.001.006.001Z"
                fill-rule="evenodd"
                fill='url("#b")'
                transform="translate(-2367.443 718.623)"
                data-name="Path 97"
              />
              <g data-name="Group 45">
                <path
                  d="M.664 30.62c-.66-2.034-.79-4.239-.557-6.256.03-.268.067-.53.11-.787.326-1.955.99-3.674 1.83-4.803.39-.523.814-1.02 1.27-1.485 2.308-2.475 5.274-3.7 6.991-4.242 4.113-1.416 8.062-1.506 9.392-1.757 6.507-1.226 19.263-.838 27.187-2.838.79-.2 3.286-1.097 4.316-1.871 1.38-1.036 2.006-2.128 1.857-3.067-.325-1.384-2.182-1.925-2.182-1.925s-1.76-.578-4.073-.53c-2.314.048-7.81-.265-7.81-.265h-2.578l.265-.587h2.474l7.649-.145h.018V.003l.258-.002c6.555-.051 8.541 3.27 6.304 6.259-.386.542-.943 1.125-1.744 1.709-3.02 2.201-9.978 3.214-9.978 3.214l-2.667.402-14.847 1.791s-4.363.555-4.676.675c-.313.12-7.761 1.977-7.761 1.977S3.643 18.212 3.983 26.03c-.024 0-.03 4.809 3.652 6.467 1.966.725 4.012.404 4.012.404l.589-.008.103-.017a.093.093 0 0 0-.003.016l4.53-.056v5.032h-4.528v.021s-.34.037-.909.037c-2.372 0-8.71-.644-10.765-7.308Z"
                  fill-rule="evenodd"
                  fill='url("#c")'
                  transform="translate(345.43 1057.472)"
                  data-name="Union 1"
                />
              </g>
              <g data-name="Group 42">
                <path
                  d="M367.349 1058.859a.276.276 0 1 0 0 .55h1.089c-.128-.18-.242-.369-.356-.55h-.733Z"
                  fill="#2e7ac5"
                  fill-rule="evenodd"
                  data-name="Path 104818"
                />
                <path
                  d="M374.423 1055.508a.276.276 0 0 1 .283-.19.269.269 0 0 1 .249.214l1.257 5.38 1.226-2.86.792-1.848a.262.262 0 0 1 .255-.168c.112 0 .213.066.256.168l.774 1.93.713-1.681a.276.276 0 0 1 .229-.169.256.256 0 0 1 .256.122l.834 1.27h.558c.094-.218.172-.443.236-.672 1.015-3.927-3.557-7.316-7.526-3.361-3.967-3.968-8.534-.579-7.519 3.362.184.647.462 1.264.827 1.83h5.198l1.102-3.327Z"
                  fill="#2e7ac5"
                  fill-rule="evenodd"
                  data-name="Path 104819"
                />
                <path
                  d="m381.148 1058.14-.672-.96-.78 1.812a.276.276 0 0 1-.512 0l-.726-1.918-2.085 4.895a.276.276 0 0 1-.255.169.282.282 0 0 1-.249-.213l-1.244-5.328-.874 2.636a.282.282 0 0 1-.262.189h-5.044a28.451 28.451 0 0 0 6.335 5.884s5.212-3.484 7.028-7.033h-.45a.289.289 0 0 1-.21-.133Z"
                  fill="#2e7ac5"
                  fill-rule="evenodd"
                  data-name="Path 104820"
                />
                <path
                  d="M382.094 1057.667h2.606v.626l-2.888-.024.282-.602Z"
                  fill="#2e7ac5"
                  fill-rule="evenodd"
                  data-name="Path 150"
                />
              </g>
              <path
                d="M2749.39 339.056Z"
                fill-rule="evenodd"
                fill='url("#d")'
                transform="translate(-2367.47 718.617)"
                data-name="Path 101"
              />
              <path
                d="m2747.348 342.321.031.002.054.002-.085-.004Z"
                fill-rule="evenodd"
                fill='url("#e")'
                transform="translate(-2365.851 716.028)"
                data-name="Path 102"
              />
              <path
                d="M2749.481 339.081Z"
                fill-rule="evenodd"
                fill='url("#f")'
                transform="translate(-2367.542 718.597)"
                data-name="Path 103"
              />
              <g data-name="Path 104">
                <path
                  d="M2769.19 338.27v1.014c-.21.002-.425.007-.646.015-2.362.086-5.144.016-7.215-.062v-.844l7.86-.123Z"
                  fill-rule="evenodd"
                  fill='url("#g")'
                  transform="translate(-2376.937 719.24)"
                />
                <path
                  d="M392.253 1057.51v1.015c-.21.001-.425.006-.645.014-2.363.086-5.145.017-7.216-.062v-.844l7.86-.123Z"
                  stroke-linejoin="round"
                  stroke-linecap="round"
                  stroke="rgba(0,0,0,0 )"
                  fill="transparent"
                />
              </g>
              <path
                d="m381.703 1058.359-.107-.005.107.005Z"
                fill-rule="evenodd"
                data-name="Path 108"
              />
              <path
                d="M381.435 1058.347h-.037.037Z"
                fill-rule="evenodd"
                data-name="Path 109"
              />
              <g data-name="Group 41">
                <g data-name="Group 39">
                  <g data-name="Group 38">
                    <g data-name="Group 36">
                      <path
                        d="M357.079 1037.085h35.174v4.123h-35.174v-4.123z"
                        fill="#1a406d"
                        fill-rule="evenodd"
                        data-name="Rectangle 10"
                      />
                    </g>
                    <g data-name="Group 37">
                      <path
                        d="M397.665 1045.074h-45.87v1.288h5.283v2.835h35.174v-2.835h5.413v-1.288Z"
                        fill="#1a406d"
                        fill-rule="evenodd"
                        data-name="Path 105"
                      />
                    </g>
                  </g>
                </g>
                <path
                  d="M392.253 1094.101v-2.835h-9.793v-17.653H367v17.653h-9.922v4.124h14.044v-17.654h7.216v17.654h19.326v-1.289h-5.411Z"
                  fill="#1a406d"
                  fill-rule="evenodd"
                  data-name="Path 107"
                />
                <g data-name="Group 40">
                  <path
                    d="M392.253 1049.133h-6.103c-1.242 0-1.758 1.728-1.758 2.608v12.53c2.844-.23 5.554-.545 7.861-1.028v-14.11Z"
                    fill="#1a406d"
                    fill-rule="evenodd"
                    data-name="Path 110"
                  />
                </g>
                <path
                  d="M364.939 1049.13h-7.86v42.394h6.102c1.242 0 1.758-2.062 1.758-3.112v-39.283Z"
                  fill="#1a406d"
                  fill-rule="evenodd"
                  data-name="Path 106"
                />
              </g>
            </g>
          </g>
          <defs>
            <linearGradient
              x1="-457.232"
              y1="21645.5"
              x2="-2.191"
              y2="127.857"
              id="a"
            >
              <stop stop-color="#1b406d" offset="0" />
              <stop stop-color="#268ddd" offset="1" />
            </linearGradient>
            <linearGradient
              x1="-688.161"
              y1="5593.03"
              x2="673.277"
              y2="-5463.3"
              id="b"
            >
              <stop stop-color="#1b406d" offset="0" />
              <stop stop-color="#268ddd" offset="1" />
            </linearGradient>
            <linearGradient x1=".179" y1="1.018" x2=".675" y2="-.006" id="c">
              <stop stop-color="#1a406d" offset="0" />
              <stop stop-color="#1a406d" offset=".15" />
              <stop stop-color="#2e7ac5" offset=".83" />
              <stop stop-color="#2e7ac5" offset="1" />
            </linearGradient>
            <linearGradient
              x1="-653.664"
              y1="5833.38"
              x2="654.068"
              y2="-5830.99"
              id="d"
            >
              <stop stop-color="#1b406d" offset="0" />
              <stop stop-color="#268ddd" offset="1" />
            </linearGradient>
            <linearGradient
              x1="-249.054"
              y1="9637.25"
              x2="-1.19"
              y2="65.742"
              id="e"
            >
              <stop stop-color="#1b406d" offset="0" />
              <stop stop-color="#268ddd" offset="1" />
            </linearGradient>
            <linearGradient
              x1="-688.518"
              y1="5487.86"
              x2="689.374"
              y2="-5485.5"
              id="f"
            >
              <stop stop-color="#1b406d" offset="0" />
              <stop stop-color="#268ddd" offset="1" />
            </linearGradient>
            <linearGradient x1="-2.472" y1="37.105" x2=".239" y2="3.744" id="g">
              <stop stop-color="#1b406d" offset="0" />
              <stop stop-color="#268ddd" offset="1" />
            </linearGradient>
          </defs>
        </svg>
      </a>
    </div>
    <nav class="footer-nav">
      <div class="footer-slice col-3">
        <div class="section">
          <h3>{{ "u_a_e" | translate }}</h3>
          <div class="section-info">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.17"
                height="16.197"
                viewBox="0 0 12.17 16.197"
              >
                <path
                  id="Path_316377"
                  data-name="Path 316377"
                  d="M11.085,2A5.884,5.884,0,0,0,5,7.669C5,11.921,11.085,18.2,11.085,18.2S17.17,11.921,17.17,7.669A5.884,5.884,0,0,0,11.085,2Zm0,7.693A2.1,2.1,0,0,1,8.912,7.669a2.1,2.1,0,0,1,2.173-2.025,2.1,2.1,0,0,1,2.173,2.025A2.1,2.1,0,0,1,11.085,9.693Z"
                  transform="translate(-5 -2)"
                  fill="#8794aa"
                />
              </svg>
            </span>
            <span>{{ "u_a_e_location" | translate }}</span>
          </div>
        </div>
        <div class="section">
          <h3>{{ "australia" | translate }}</h3>
          <div class="section-info">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.17"
                height="16.197"
                viewBox="0 0 12.17 16.197"
              >
                <path
                  id="Path_316377"
                  data-name="Path 316377"
                  d="M11.085,2A5.884,5.884,0,0,0,5,7.669C5,11.921,11.085,18.2,11.085,18.2S17.17,11.921,17.17,7.669A5.884,5.884,0,0,0,11.085,2Zm0,7.693A2.1,2.1,0,0,1,8.912,7.669a2.1,2.1,0,0,1,2.173-2.025,2.1,2.1,0,0,1,2.173,2.025A2.1,2.1,0,0,1,11.085,9.693Z"
                  transform="translate(-5 -2)"
                  fill="#8794aa"
                />
              </svg>
            </span>
            <span>{{ "australia_location" | translate }}</span>
          </div>
        </div>
        <div class="app-info">
          <div class="img">
            <!-- <a
              href="https://apps.apple.com/us/app/hlthera/id1583577769"
              target="_blank"
            >
              <img src="../assets/images/app-store.png" alt="" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.hlthera.hltherasocialnetwork"
              target="_blank"
            >
              <img src="../assets/images/google-play.png" alt="" />
            </a> -->
          </div>
        </div>
      </div>
      <div id="list-container" class="footer-slice col-3">
        <h3 (click)="activate(hlthera)">{{ "hlthera" | translate }}</h3>
        <ul #hlthera>
          <li (click)="goTostayTuned()">{{ "hlthera" | translate }}</li>
          <li (click)="goTostayTuned()">{{ "hlthera_engage" | translate }}</li>
          <li (click)="goTostayTuned()">
            {{ "hlthera_professional_services" | translate }}
          </li>
          <li (click)="goTostayTuned()">
            {{ "hlthera_foundation" | translate }}
          </li>
        </ul>
      </div>
      <div id="list-container" class="footer-slice col-3">
        <h3 (click)="activate(for_providers)">
          {{ "for_providers" | translate }}
        </h3>
        <ul #for_providers>
          <li (click)="goTostayTuned()">{{ "healers" | translate }}</li>
          <li (click)="goTostayTuned()">{{ "health_centers" | translate }}</li>
        </ul>
      </div>
      <div id="list-container" class="footer-slice col-3">
        <h3 (click)="activate(company)">{{ "company" | translate }}</h3>
        <ul #company>
          <li (click)="goTostayTuned()">{{ "meet_the_team" | translate }}</li>
          <li (click)="goTostayTuned()">{{ "careers" | translate }}</li>
          <li (click)="goTostayTuned()">{{ "contact_us" | translate }}</li>
          <li (click)="goTostayTuned()">
            {{ "terms_&_conditions" | translate }}
          </li>
          <li (click)="goTostayTuned()">{{ "privacy_policy" | translate }}</li>
        </ul>
      </div>
    </nav>
    <div class="footer-tail">
      <div>
        <p>Copyright © 2024 Hlthera FZCO. All rights reserved.</p>
      </div>
      <div class="social-icons">
        <a
          class="sochial-icon"
          href="https://www.facebook.com/Hlthera"
          target="_blank"
        >
          <div [replaceSvg]="sochialIcons.facbook"></div>
        </a>
        <a
          class="sochial-icon"
          href="https://www.instagram.com/hlthera/?igshid=o95lr2j1w67z"
          target="_blank"
        >
          <div [replaceSvg]="sochialIcons.instigram"></div>
        </a>
        <a
          class="sochial-icon"
          href="https://x.com/hlthera?s=11"
          target="_blank"
        >
          <div [replaceSvg]="sochialIcons.twitter"></div>
        </a>
        <a
          class="sochial-icon"
          href="https://www.linkedin.com/company/hlthera/"
          target="_blank"
        >
          <div [replaceSvg]="sochialIcons.linkedIn"></div>
        </a>
      </div>
    </div>
  </div>
</footer>
